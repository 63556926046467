<!-- <script src="../../../public/gis/map/gis/js/openlayers/ol-contextmenu.js"></script> -->
<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        :icon="isCustomizerOpen === true ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        size="15"
      />
    </b-link>

    <b-row>
      <b-col cols="12">
        <div style="padding:10px; overflow-y:scroll; min-height:935px; max-height:935px;">
          <h5
            class="font-weight-bolder"
            style="margin:10px 0px 15px 0px;"
          >
            검색한 건물 목록
          </h5>
          <div
            v-for="(val, index) in items"
            :key="index +'l'"
            class="searchedBuilding"
          >
            <div>
              <span>
                <img src="/gis/map/common/images/icon/icon_building.png">
                <p class="sbSubheading">건물명</p>
              </span>
              <span v-if="val.bld_nm != null && val.bld_nm!=''">
                {{val.bld_nm}}
              </span>
              <span v-else>
                -
              </span>
            </div>
            <div>
              <span>
                <img src="/gis/map/common/images/icon/icon_location.png">
                <p class="sbSubheading">위치</p>
              </span>
              <span class="sbContent" @click="goMap(val.mgmBldPk, val.mapngGrade, val.layerKorName,val.layerId, val.geometry )">
                {{ val.sido_nm }} {{ val.sigungu_nm }} {{ val.dong_nm }}
              </span>
            </div>
            <div>
              <span class="searchedBtnSpan">
                <button
                  class="searchedBtn buildingBtn"
                  @click="parentBuildInfo(val.mgmBldPk, val.mapngGrade, val.layerKorName,val.layerId, val.geometry )"
                >건물정보</button>
              </span>
              <span class="searchedBtnSpan">
                <button
                  class="searchedBtn energyBtn"
                  @click="parentEnergyUse(val.mgmBldPk, val.mapngGrade, val.layerKorName,val.layerId, val.geometry)"
                >에너지소비량
                </button>
              </span>
              <span class="searchedBtnSpan">
                <button
                  class="searchedBtn newEnergyBtn"
                  @click="parentNewEnergyUse(val.mgmBldPk, val.mapngGrade, val.layerKorName,val.layerId, val.geometry)"
                >신재생포텐셜
                </button>
              </span>
            </div>
          </div>
        </div>

      </b-col>
    </b-row>
    <!-- /Toggler -->

  </div>
</template>

<script>
import {
  BLink, BRow, BCol
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // BSV
    BLink,
    BRow,
    BCol
  },
  directives: {
    Ripple,
  },
  props: {
    bldInfoData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isCustomizerOpen: false,

      items: [],
    };
  },
  computed: {
    checkBldInfoData() {
      return this.bldInfoData;
    },

  },
  watch: {
    checkBldInfoData(val) {

      this.mgmBldPk = val.mgmBldPk
      this.$store.dispatch("main/FETCH_TEMP_BUILD_INFO_DATA", {
        dataCode: "tempBuildInfo",
        mgmBldPk: this.mgmBldPk
      }).then(() => {
        this.$store.state.main.tempBuildInfo.forEach((element) => {
          const temp = {};
          temp.bld_nm = element.bld_nm;
          temp.sido_nm = element.sido_nm;
          temp.sigungu_nm = element.sigungu_nm;
          temp.dong_nm = element.dong_nm;
          temp.mgm_bld_pk = element.mgm_bld_pk;

          temp.mgmBldPk = val.mgmBldPk;
          temp.mapngGrade = val.mapngGrade;
          temp.layerKorName = val.layerKorName;
          temp.layerId = val.layerId;
          temp.geometry = val.geometry;

          this.items.unshift(temp)
        })
      }).catch(() => {
        console.error('오류')
      })
    },

  },
  mounted() {

  },
  created() {
    this.$store.dispatch("main/FETCH_TEMP_BUILD_INFO_DATA", {
      dataCode: "tempBuildInfo",
      mgmBldPk: this.bldInfoData.mgmBldPk
    }).then(() => {
      // this.items = this.$store.state.main.tempBuildInfo;
      this.$store.state.main.tempBuildInfo.forEach((element) => {
        const temp = {};
        temp.bld_nm = element.bld_nm;
        temp.sido_nm = element.sido_nm;
        temp.sigungu_nm = element.sigungu_nm;
        temp.dong_nm = element.dong_nm;
        temp.mgm_bld_pk = element.mgm_bld_pk;

        temp.mgmBldPk = this.bldInfoData.mgmBldPk;
        temp.mapngGrade = this.bldInfoData.mapngGrade;
        temp.layerKorName = this.bldInfoData.layerKorName;
        temp.layerId = this.bldInfoData.layerId;
        temp.geometry = this.bldInfoData.geometry;

        this.items.unshift(temp)
      })
    }).catch(() => {
      console.error('오류')
    })
  },
  methods: {
    parentEnergyUse(mgmBldPk, mapngGrade, layerKorName, layerId, geometry) {
      const temp = {};
      temp.mgmBldPk = mgmBldPk;
      temp.mapngGrade = mapngGrade;
      temp.layerKorName = layerKorName;
      temp.layerId = layerId;
      temp.geometry = geometry;

      this.isCustomizerOpen = !this.isCustomizerOpen
      this.$parent.onClickEnergyUse(temp);
    },
    parentBuildInfo(mgmBldPk, mapngGrade, layerKorName, layerId, geometry) {
      const temp = {};
      temp.mgmBldPk = mgmBldPk;
      temp.mapngGrade = mapngGrade;
      temp.layerKorName = layerKorName;
      temp.layerId = layerId;
      temp.geometry = geometry;

      this.isCustomizerOpen = !this.isCustomizerOpen
      this.$parent.onClickBuildInfo(temp);
    },
    parentNewEnergyUse(mgmBldPk, mapngGrade, layerKorName, layerId, geometry) {
      const temp = {};
      temp.mgmBldPk = mgmBldPk;
      temp.mapngGrade = mapngGrade;
      temp.layerKorName = layerKorName;
      temp.layerId = layerId;
      temp.geometry = geometry;

      this.isCustomizerOpen = !this.isCustomizerOpen
      this.$parent.onClickNewEnergyUse(temp);
    },
    goMap(mgmBldPk, mapngGrade, layerKorName, layerId, geometry) {
      const temp = {};
      temp.mgmBldPk = mgmBldPk;
      temp.mapngGrade = mapngGrade;
      temp.layerKorName = layerKorName;
      temp.layerId = layerId;
      temp.geometry = geometry;
      gis.search.moveToBldGeom(map, temp);
    }
  },

}
</script>

<style scoped>
.customizer .customizer-toggle {
  color: #fff !important;
  display: block;
  position: absolute;
  top: 60%;
  width: 30px;
  height: 30px;
  line-height: 40px;
  left: -31px;
}

.searchedBuilding {
  background: #f6f6f9;
  border: 1px solid #cecece;
  padding: 10px;
  margin-bottom: 10px;
}

.searchedBuilding img {
  vertical-align: text-bottom;
}

.sbSubheading {
  display: inline-block;
  font-weight: bold;
  width: 55px;
  margin-left: 7px;
  color: #000000;
}

.sbContent {
  color: #5e5873;
  cursor:pointer;
}

.searchedBtnSpan {
  display: inline-block;
  width: 33%;
}

.searchedBtn {
  width: 105px;
  text-align: right;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  padding: 3px;
}

.buildingBtn {
  background: #ffffff url("/gis/map/common/images/icon/icon_buildingBtn.png") no-repeat 15px 5px !important;
  padding-right: 15px;
}

.buildingBtn:hover {
  background: #CCCCCEFF url("/gis/map/common/images/icon/icon_buildingBtn.png") no-repeat 15px 5px !important;
}

.energyBtn {
  background: #ffffff url("/gis/map/common/images/icon/icon_energyBtn.png") no-repeat 5px 5px !important;
}

.energyBtn:hover {
  background: #CCCCCEFF url("/gis/map/common/images/icon/icon_energyBtn.png") no-repeat 5px 5px !important;
}

.newEnergyBtn {
  background: #ffffff url("/gis/map/common/images/icon/icon_newEnergyBtn.png") no-repeat 5px 5px !important;
}

.newEnergyBtn:hover {
  background: #CCCCCEFF url("/gis/map/common/images/icon/icon_newEnergyBtn.png") no-repeat 5px 5px !important;
}
</style>
