<template>
	<div style="padding:20px 0px;">
		<span class="floorSelTitle">층 선택</span>
		<!-- <v-select
			id="floorSel"
			v-model="selectedFloor"
			label="name"
			placeholder="선택"
			:options="visibleFloorList"
			@input="submitData($event)"
		/> -->
		<b-form-select
			id="floorSel"
			v-model="selectedFloor"
			placeholder="선택"
			:options="visibleFloorList"
			@input="submitData($event)"
		/>
	</div>
</template>

<script>
import vSelect from "vue-select";
import { BFormSelect } from 'bootstrap-vue'

export default {
	components: {
    vSelect,
		BFormSelect,
  },
	props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
		lyrIdStr: {
			type: String,
			required: true,
		},
  },
	data() {
    return {
			floorList: [],
			visibleFloorList: [{ value: null, text: '선택', disabled: true }],
			selectedFloor: null,
    };
  },
	computed: {
  },
	async created() {
		this.floorList = await this.$store.dispatch('main/FETCH_FLOOR_NO', { mgmBldPk: this.mgmBldPk, lyrIdStr: this.lyrIdStr });
		this.drawSelect(this.floorList);
	},
	methods: {
		submitData() {
			this.$emit('floorData', this.selectedFloor);
		},
		drawSelect(dataList) {
			if (dataList.length !== 0) {
				for (let i = 0; i < dataList.length; i += 1) {
					let name = '';
					let disableFlag = '';
					dataList[i].total_floor_no > 0 ? name = `${dataList[i].total_floor_no}층` : name = `B${(dataList[i].total_floor_no) * -1}층`;
					dataList[i].viewable_floor_no === null ? disableFlag = true : disableFlag = false;
					this.visibleFloorList.push({ text: name, value: dataList[i].total_floor_no, disabled: disableFlag });
				}
			}
		},
	},
}
</script>

<style>
.floorSelTitle{
	display:inline-block;
	width:20%;
}
#floorSel{
	display:inline-block;
	width:80%;
}
#floorSel option:disabled{
	color:#c5c5c5;
}
</style>
